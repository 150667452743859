import { useEffect } from "react";
import {
  init,
  addTracingExtensions,
  reactRouterV6BrowserTracingIntegration,
} from "@sentry/react";
import { useFlagsStatus, useFlag } from "@unleash/proxy-client-react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { SENTRY_ENABLED_FLAG } from "$/feature-flags/constants";

export function useInitSentry(): void {
  const { flagsReady } = useFlagsStatus();

  const isSentryEnabled = useFlag(SENTRY_ENABLED_FLAG);

  useEffect(() => {
    if (!flagsReady || !isSentryEnabled) {
      return;
    }

    if (
      !process.env.SENTRY_DSN ||
      process.env.SENTRY_DSN === "" ||
      !process.env.SENTRY_RELEASE ||
      process.env.SENTRY_RELEASE === ""
    ) {
      return;
    }

    addTracingExtensions();

    init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.MODE,
      release: process.env.SENTRY_RELEASE,
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
      denyUrls: [
        // Ignore all webkit-extension schemas
        /webkit-masked-url:/,
      ],
    });
  }, [flagsReady, isSentryEnabled]);
}
