import { FC } from "react";

import AddressBook from "$/ui/assets/svgs/icons/address-book.svg";
import ArrowClockwise from "$/ui/assets/svgs/icons/arrow-clockwise.svg";
import ArrowElbowRightUp from "$/ui/assets/svgs/icons/arrow-elbow-right-up.svg";
import ArrowFatLinesUp from "$/ui/assets/svgs/icons/arrow-fat-lines-up.svg";
import ArrowLeft from "$/ui/assets/svgs/icons/arrow-left.svg";
import ArrowRight from "$/ui/assets/svgs/icons/arrow-right.svg";
import ArrowsClockwise from "$/ui/assets/svgs/icons/arrows-clockwise.svg";
import ArrowsOut from "$/ui/assets/svgs/icons/arrows-out.svg";
import ArrowUpRight from "$/ui/assets/svgs/icons/arrow-up-right.svg";
import Bank from "$/ui/assets/svgs/icons/bank.svg";
import BellRinging from "$/ui/assets/svgs/icons/bell-ringing.svg";
import Blank from "$/ui/assets/svgs/icons/blank.svg";
import Barcode from "$/ui/assets/svgs/icons/barcode.svg";
import Book from "$/ui/assets/svgs/icons/book.svg";
import Bookmark from "$/ui/assets/svgs/icons/bookmark.svg";
import CalendarBlank from "$/ui/assets/svgs/icons/calendar-blank.svg";
import CalendarCheck from "$/ui/assets/svgs/icons/calendar-check.svg";
import CaretDown from "$/ui/assets/svgs/icons/caret-down.svg";
import CaretLeft from "$/ui/assets/svgs/icons/caret-left.svg";
import CaretRight from "$/ui/assets/svgs/icons/caret-right.svg";
import CaretUp from "$/ui/assets/svgs/icons/caret-up.svg";
import Chat from "$/ui/assets/svgs/icons/chat.svg";
import Check from "$/ui/assets/svgs/icons/check.svg";
import CheckCircle from "$/ui/assets/svgs/icons/check-circle.svg";
import XCircle from "$/ui/assets/svgs/icons/x-circle.svg";
import Copy from "$/ui/assets/svgs/icons/copy.svg";
import Cross from "$/ui/assets/svgs/icons/cross.svg";
import DotsThreeOutline from "$/ui/assets/svgs/icons/dots-three-outline.svg";
import Download from "$/ui/assets/svgs/icons/download.svg";
import DotsThreeOutlineVertical from "$/ui/assets/svgs/icons/dots-three-outline-vertical.svg";
import ExternalLink from "$/ui/assets/svgs/icons/external-link.svg";
import Eye from "$/ui/assets/svgs/icons/eye.svg";
import FallbackImage from "$/ui/assets/svgs/icons/fallback-image.svg";
import FileText from "$/ui/assets/svgs/icons/file-text.svg";
import Flag from "$/ui/assets/svgs/icons/flag.svg";
import Info from "$/ui/assets/svgs/icons/info.svg";
import InfoRounded from "$/ui/assets/svgs/icons/info-rounded.svg";
import Laptop from "$/ui/assets/svgs/icons/laptop.svg";
import Link from "$/ui/assets/svgs/icons/link.svg";
import List from "$/ui/assets/svgs/icons/list.svg";
import ListDashes from "$/ui/assets/svgs/icons/list-dashes.svg";
import Lock from "$/ui/assets/svgs/icons/lock.svg";
import Logout from "$/ui/assets/svgs/icons/logout-icon.svg";
import MagnifyingGlass from "$/ui/assets/svgs/icons/magnifying-glass.svg";
import Medal from "$/ui/assets/svgs/icons/medal.svg";
import NumberCircleOne from "$/ui/assets/svgs/icons/number-circle-one.svg";
import NumberCircleTwo from "$/ui/assets/svgs/icons/number-circle-two.svg";
import NumberCircleThree from "$/ui/assets/svgs/icons/number-circle-three.svg";
import NumberCircleFour from "$/ui/assets/svgs/icons/number-circle-four.svg";
import NumberCircleFive from "$/ui/assets/svgs/icons/number-circle-five.svg";
import PencilSimple from "$/ui/assets/svgs/icons/pencil-simple.svg";
import Performance from "$/ui/assets/svgs/icons/performance.svg";
import Plus from "$/ui/assets/svgs/icons/plus.svg";
import Prohibit from "$/ui/assets/svgs/icons/prohibit.svg";
import Question from "$/ui/assets/svgs/icons/question.svg";
import Receipt from "$/ui/assets/svgs/icons/receipt.svg";
import SepaIcon from "$/ui/assets/svgs/icons/sepa_icon.svg";
import ShoppingCart from "$/ui/assets/svgs/icons/shopping-cart.svg";
import SpinnerGap from "$/ui/assets/svgs/icons/spinner-gap.svg";
import Tag from "$/ui/assets/svgs/icons/tag.svg";
import Trash from "$/ui/assets/svgs/icons/trash.svg";
import TrashSimple from "$/ui/assets/svgs/icons/trash-simple.svg";
import Truck from "$/ui/assets/svgs/icons/truck.svg";
import User from "$/ui/assets/svgs/icons/user.svg";
import WarningCircle from "$/ui/assets/svgs/icons/warning-circle.svg";
import Warning from "$/ui/assets/svgs/icons/warning.svg";
import { Svg } from "./icon.styles";

export const iconTypes = {
  addressBook: AddressBook,
  arrowClockwise: ArrowClockwise,
  arrowElbowRightUp: ArrowElbowRightUp,
  arrowFatLinesUp: ArrowFatLinesUp,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowsClockwise: ArrowsClockwise,
  arrowsOut: ArrowsOut,
  arrowUpRight: ArrowUpRight,
  bank: Bank,
  bellRinging: BellRinging,
  blank: Blank,
  barcode: Barcode,
  book: Book,
  bookmark: Bookmark,
  calendarBlank: CalendarBlank,
  calendarCheck: CalendarCheck,
  caretLeft: CaretLeft,
  caretRight: CaretRight,
  caretDown: CaretDown,
  caretUp: CaretUp,
  chat: Chat,
  check: Check,
  checkCircle: CheckCircle,
  copy: Copy,
  cross: Cross,
  dotsThreeOutline: DotsThreeOutline,
  download: Download,
  dotsThreeOutlineVertical: DotsThreeOutlineVertical,
  externalLink: ExternalLink,
  eye: Eye,
  fallbackImage: FallbackImage,
  fileText: FileText,
  flag: Flag,
  info: Info,
  infoRounded: InfoRounded,
  laptop: Laptop,
  link: Link,
  listDashes: ListDashes,
  list: List,
  lock: Lock,
  logout: Logout,
  magnifyingGlass: MagnifyingGlass,
  medal: Medal,
  numberCircleOne: NumberCircleOne,
  numberCircleTwo: NumberCircleTwo,
  numberCircleThree: NumberCircleThree,
  numberCircleFour: NumberCircleFour,
  numberCircleFive: NumberCircleFive,
  pencilSimple: PencilSimple,
  performance: Performance,
  plus: Plus,
  prohibit: Prohibit,
  question: Question,
  receipt: Receipt,
  sepa: SepaIcon,
  shoppingCart: ShoppingCart,
  spinnerGap: SpinnerGap,
  tag: Tag,
  trash: Trash,
  trashSimple: TrashSimple,
  truck: Truck,
  user: User,
  warning: Warning,
  warningCircle: WarningCircle,
  xCircle: XCircle,
} as const;

export type IconName = keyof typeof iconTypes;

export interface IconProps {
  title?: string;
  className?: string;
  name: IconName;
}

export const Icon: FC<IconProps> = ({ title, className, name }: IconProps) => {
  const IconType = name && iconTypes[name];
  return <Svg as={IconType} className={className} title={title} />;
};
