import { FC, ReactNode } from "react";

import {
  Card,
  Background,
  Container,
  Content,
  Header,
  Logo,
  ContentWrapper,
} from "./full-screen-box-layout.styles";
import { Logo as HeaderLogo } from "$/ui/components/logo/logo";

export interface FullScreenBoxLayoutProps {
  children?: ReactNode;
  hasColorfulBackground?: boolean;
}

export const FullScreenBoxLayout: FC<FullScreenBoxLayoutProps> = ({
  children,
  hasColorfulBackground = false,
}) => {
  return (
    <Container>
      <Header>
        <HeaderLogo size="s" />
      </Header>
      <Content>
        <Background hasColorfulBackground={hasColorfulBackground}>
          <ContentWrapper>
            <Logo size="s" />
            <Card>{children}</Card>
          </ContentWrapper>
        </Background>
      </Content>
    </Container>
  );
};
