import styled from "styled-components";
import { Illustration as DefaultIllustration } from "$/ui/components/illustration/illustration";
import { Heading as DefaultHeading } from "$/ui/components/heading/heading";
import { sizes, spacing } from "$/ui/styles/tokens/tokens";

export const Illustration = styled(DefaultIllustration)`
  max-width: ${sizes.sz240};
  margin-block-end: ${spacing.sp032};
`;

export const Heading = styled(DefaultHeading)`
  margin-block-end: ${spacing.sp016};
`;
