import styled, { css } from "styled-components";
import { TextProps } from "$/ui/components/text/text";
import { linesClamp, setTypography } from "$/ui/styles/mixins";
import { spacing } from "$/ui/styles/tokens/tokens";

type ContainerProps = Pick<TextProps, "typography" | "isTruncated">;

export const Container = styled.div<ContainerProps>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ typography }) =>
    typography &&
    css`
      margin: ${spacing.sp004} 0;
      padding: 0;
      ${setTypography(typography)};
    `}

  ${({ isTruncated }) =>
    isTruncated &&
    css`
      width: 100%;
      vertical-align: bottom;
      ${linesClamp()};
    `}
`;
