import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Container, Ring, SpinnerSize } from "./spinner.styles";

interface SpinnerProps {
  className?: string;
  size?: SpinnerSize;
}

export const Spinner: FC<SpinnerProps> = ({ className, size = "l" }) => {
  const { t } = useTranslation();

  return (
    <Container
      aria-label={t("loading")}
      role="img"
      size={size}
      className={className}
    >
      <Ring size={size} />
      <Ring size={size} />
      <Ring size={size} />
      <Ring size={size} />
      <Ring size={size} />
    </Container>
  );
};
