import styled from "styled-components";
import {
  colorfulBackground,
  maxContent,
  whiteCardWithRoundedBorders,
} from "$/ui/styles/mixins";
import { Logo as DefaultLogo } from "$/ui/components/logo/logo";
import { borderRadius, colors, spacing } from "$/ui/styles/tokens/tokens";
import { px2rems } from "$/ui/utils/px-to-rem";
import { from } from "$/ui/styles/media-queries";

const CONTENT_CARD_MAX_WIDTH = 422;

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: ${spacing.sp016};

  ${from.l} {
    position: static;
    padding: ${spacing.sp012};
    background-color: ${colors.white};
  }

  ${from.xl} {
    display: none;
  }
`;

export const Logo = styled(DefaultLogo)`
  display: none;

  ${from.xl} {
    display: block;
  }
`;

export const Content = styled.main`
  display: flex;
  flex: 1;

  ${from.l} {
    padding: 0 ${spacing.sp024} ${spacing.sp024} ${spacing.sp024};
  }
  ${from.xl} {
    padding: ${spacing.sp024};
  }
`;

export const Background = styled.div<{ hasColorfulBackground: boolean }>`
  flex: 1;
  display: flex;
  background-color: ${colors.grey04};

  ${from.l} {
    border-radius: ${borderRadius.xl};
  }

  ${({ hasColorfulBackground }) =>
    hasColorfulBackground ? colorfulBackground() : undefined};
`;

export const ContentWrapper = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.sp096} ${spacing.sp016} ${spacing.sp032};

  ${from.l} {
    ${maxContent};
  }

  ${from.xl} {
    display: grid;
    grid-template-rows: auto 1fr;
    justify-items: center;
    grid-row-gap: ${spacing.sp024};
  }
`;

export const Card = styled.div`
  ${whiteCardWithRoundedBorders}
  max-width: ${px2rems(CONTENT_CARD_MAX_WIDTH)};
`;
