import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import EnTranslation from "./translations/en/translation.json";
import DeTranslation from "./translations/de/translation.json";

interface Language {
  nativeName: string;
}
interface SupportedLanguages {
  [index: string]: Language;
}

export const lngs: SupportedLanguages = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

export const resources = {
  en: { EnTranslation },
  de: { DeTranslation },
} as const;

let isInitialized = false;

i18n.on("languageChanged", () => {
  document.body.dir = i18n.dir();
});

export const initI18n = (): void => {
  if (!isInitialized) {
    isInitialized = true;
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: "en",
        interpolation: {},
        load: "languageOnly",
        react: {
          useSuspense: true,
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ["br", "strong", "a"],
        },
        backend: {
          queryStringParams: { v: process.env.commit_hash || "dev" },
        },
        returnNull: false,
      });
  }
};

export const language2LetterCode = (): string => {
  const { language } = i18n;
  return language.slice(0, 2);
};

export { default as i18n } from "i18next";
