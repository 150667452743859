import { createContext, ReactNode, useContext, useMemo } from "react";

export enum TopiAppPage {
  ACCEPTED_OFFER = "accepted_offer",
  ANONYMIZED = "anonymized",
  ERROR = "error",
  NOT_FOUND = "not_found",
  OFFER = "offer",
  ORDER_SUMMARY = "order_summary",
  REVIEW_OFFER = "review_offer",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  SIGN_UP = "sign_up",
  PREVIEW_OFFER = "preview_offer",
  ADDONS_OFFER = "addons_offer",
  ID_VERIFICATION_NEEDED = "id_verification_needed",
  ID_VERIFICATION_COMPLETED = "id_verification_completed",
}

export enum SellerAppPage {
  LOGIN = "login",
  BUILD_BASKET = "build_basket",
  CUSTOMER_INFO = "customer_info",
  SHIPPING_INFO = "shipping_info",
  OFFER_DETAILS = "offer_details",
  OFFER_LIST = "offer_list",
  ORDERS_LIST = "orders_list",
  PERFORMANCE = "performance",
  ADD_INFO = "add_info",
  ORDER_DETAILS = "order_details",
}

export enum EcPortalAppPage {
  DEVICES = "devices",
  DEVICE_DETAILS = "device-details",
  ERROR = "error",
  NOT_FOUND = "not_found",
  PAYMENTS = "payments",
  ORDERS = "orders",
  ORDER_DETAILS = "order-details",
  SUPPORT = "support",
}

export type AnalyticsContextData = {
  offerID?: string;
  page?: TopiAppPage | SellerAppPage | EcPortalAppPage;
};

type InternalAnalyticsContextData = AnalyticsContextData & {
  screenHeight?: number;
  screenWidth?: number;
};

const AnalyticsContextDataContext = createContext<InternalAnalyticsContextData>(
  {}
);

// Create an analytics hook that we can use with other components.
export const useAnalyticsContext = (): InternalAnalyticsContextData => {
  const result = useContext(AnalyticsContextDataContext);
  if (!result) {
    throw new Error(
      "AnalyticsContextDataContext used outside of its Provider!"
    );
  }
  return result;
};

type Props = {
  contextData: AnalyticsContextData;
  children: ReactNode;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const AnalyticsContextProvider = ({ children, contextData }: Props) => {
  const parentContext = useAnalyticsContext();

  const contextDataInternal = useMemo(() => {
    const screenHeight = window.screen.width * window.devicePixelRatio;
    const screenWidth = window.screen.height * window.devicePixelRatio;
    const currentApp = process.env.CURRENT_APP;

    return {
      ...parentContext,
      ...contextData,
      screenHeight,
      screenWidth,
      currentApp,
    };
  }, [contextData, parentContext]);

  return (
    <AnalyticsContextDataContext.Provider value={contextDataInternal}>
      {children}
    </AnalyticsContextDataContext.Provider>
  );
};
