import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { setLocaleHeaders } from "$/api/common";

export function useRefreshAPILanguage(): void {
  const { i18n } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    setLocaleHeaders(i18n.language);
  }, [i18n.language]);
}
