// eslint-disable-next-line import/no-cycle
import { Analytics } from "./analytics";
import { AnalyticsContextData as TAnalyticsContextData } from "./context";
import { Events } from "./events";

export class Logger {
  analytics: Analytics;

  context: TAnalyticsContextData;

  constructor(analytics: Analytics, context: TAnalyticsContextData) {
    this.analytics = analytics;
    this.context = context;
  }

  trackEvent(
    event: Events,
    extraData?: { [k: string]: string | number }
  ): void {
    if (!this.analytics.analyticsEnabled) {
      return;
    }
    this.analytics.analytics?.track(event, { ...this.context, ...extraData });
  }

  pageLoad(
    pageName: string,
    extraData?: { [k: string]: string | number }
  ): void {
    if (!this.analytics.analyticsEnabled) {
      return;
    }

    // eslint-disable-next-line i18next/no-literal-string
    const currentApp = process.env.CURRENT_APP || "undefined_app";

    this.analytics.analytics?.page(currentApp, pageName, {
      ...this.context,
      ...extraData,
    });
  }

  identify(userID: string, extraData?: { [k: string]: string }): void {
    if (!this.analytics.analyticsEnabled) {
      return;
    }

    // eslint-disable-next-line i18next/no-literal-string
    const currentApp = process.env.CURRENT_APP || "undefined_app";

    this.analytics.analytics?.identify(userID, { currentApp, ...extraData });
  }
}
