import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Spinner } from "$/ui/components/spinner/spinner";
import { Text } from "$/ui/components/text/text";

export const SpinnerAndText: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Spinner />
      <Text>{t("loading")}</Text>
    </>
  );
};
