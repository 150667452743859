import { useFlagsStatus } from "@unleash/proxy-client-react";
import { FC, Suspense, lazy } from "react";
import { AvailableAppNames } from "$/config";
import { LoadingLayout } from "$/ui/layouts/loading-layout/loading-layout";
import { initI18n } from "$/i18n/config";
import { useInitSentry } from "$/hooks/use-init-sentry";
import { useRefreshAPILanguage } from "$/hooks/use-refresh-api-language";

const SellerAppWithAuthAndStore = lazy(
  () => import("$/seller-app/seller-app-with-auth-and-store")
);

const CheckoutAppWithStore = lazy(
  () => import("$/checkout-app/checkout-app-with-store")
);

const ECPortalAppWithAuthAndStore = lazy(
  () => import("$/ec-portal-app/ec-portal-app-with-auth-and-store")
);

export interface AppProps {
  appType: string | undefined;
}

const appTypeChooser = (appType?: string): React.ReactElement => {
  switch (appType) {
    case AvailableAppNames.ecPortal:
      return <ECPortalAppWithAuthAndStore />;
    case AvailableAppNames.seller:
      return <SellerAppWithAuthAndStore />;
    default:
      return <CheckoutAppWithStore />;
  }
};

export const App: FC<AppProps> = ({ appType }) => {
  initI18n();
  useInitSentry();
  useRefreshAPILanguage();
  const { flagsReady } = useFlagsStatus();
  if (!flagsReady) {
    return <LoadingLayout />;
  }
  return (
    <Suspense fallback={<LoadingLayout />}>{appTypeChooser(appType)}</Suspense>
  );
};
