import { ApiResourceStatus } from "$/common/models";

export const localeHeader: Record<string, string> = {};

export function setLocaleHeaders(locale: string): void {
  localeHeader["Accept-Language"] = locale;
}

export const dummyAuthFunction = async (): Promise<string> => {
  return "dummy_token";
};

export interface ApiError {
  code: number;
  message: string;
  errorName?: string;
}

export const mapApiErrorToApiResourceStatus = (
  apiError?: ApiError
): ApiResourceStatus => {
  if (!apiError?.code) {
    return ApiResourceStatus.ClientError;
  }
  if (apiError.code >= 500) {
    return ApiResourceStatus.ServerError;
  }
  if (apiError.code === 404) {
    return ApiResourceStatus.NotFound;
  }
  return ApiResourceStatus.ClientError;
};
