import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { ErrorBoundary } from "@sentry/react";
import { StrictMode, Suspense } from "react";
import { FeatureFlagsProvider } from "$/feature-flags/feature-flags-provider";
import { LoadingLayout } from "$/ui/layouts/loading-layout/loading-layout";
import { ErrorPage } from "$/common/features/error-page/error-page";
import { AnalyticsProvider } from "$/analytics/analytics";
import { App } from "$/app";
import { GlobalStyleCheckout } from "$/ui/styles/global-style/global-style-checkout";

document.body.append(
  Object.assign(document.createElement(`div`), { id: "root" })
);

Modal.setAppElement("#root");

const currentApp = process.env.CURRENT_APP;
const GlobalStyle = GlobalStyleCheckout;

const container = document.querySelector("#root");

if (container === null) {
  throw new Error("root container is null");
}

const root = createRoot(container);
root.render(
  <StrictMode>
    <ErrorBoundary fallback={<ErrorPage />}>
      <GlobalStyle />
      {/* The LoadingLayout depends on localized strings, so we need a fallback for that */}
      <Suspense fallback={<></>}>
        <Suspense fallback={<LoadingLayout />}>
          <FeatureFlagsProvider
            url={process.env.UNLEASH_PROXY_URL}
            proxyKey={process.env.UNLEASH_PROXY_KEY}
            currentApp={process.env.CURRENT_APP}
          >
            <AnalyticsProvider writeKey={process.env.SEGMENT_WRITE_KEY}>
              <App appType={currentApp} />
            </AnalyticsProvider>
          </FeatureFlagsProvider>
        </Suspense>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>
);
