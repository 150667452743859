import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Text } from "$/ui/components/text/text";
import { FullScreenBoxLayout } from "$/ui/layouts/full-screen-box-layout/full-screen-box-layout";
import {
  Illustration,
  Heading,
} from "$/common/features/error-page/error-page.styles";
import { TopiAppPage } from "$/analytics/context";
import { useAnalytics } from "$/analytics/analytics";

export const ErrorPage: FC = () => {
  const { t } = useTranslation();

  const analytics = useAnalytics();

  useEffect(() => {
    analytics.pageLoad(TopiAppPage.ERROR);
    // On purpose, as we want to log only on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenBoxLayout>
      <header>
        <Illustration name="error" />
        <Heading level={1} typography="headline3">
          {t("errorPage.title")}
        </Heading>
      </header>
      <Text>
        <Trans i18nKey="errorPage.body" />
      </Text>
    </FullScreenBoxLayout>
  );
};
