// TODO: we should probably rethink this
import "react-toastify/dist/ReactToastify.css";

import styled, { css } from "styled-components";
import { Icon as DefaultIcon } from "$/ui/components/icon/icon";
import { Text as DefaultText } from "$/ui/components/text/text";
import {
  borderRadius,
  colors,
  shadows,
  sizes,
  spacing,
  zIndices,
} from "$/ui/styles/tokens/tokens";
import type { Level } from "./toast";

export const resetReactToastify = css`
  /* This code needs to be called in a root level (see global styles) */
  .Toastify__toast-container {
    z-index: ${zIndices.toast};
    width: auto;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast {
    display: block;
    padding: 0;
    box-shadow: none;
    opacity: 1;
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background: none;
  }

  .Toastify__toast-theme--light {
    background: none;
  }
`;

export const Container = styled.div<{
  level: Level;
}>`
  ${({ level }) => {
    switch (level) {
      case "error":
        return `
           --background-color: ${colors.red50};
          `;
      case "success":
        return `
            --background-color: ${colors.green70};
          `;
      default:
        return `
            background-color: ${colors.red50};
            `;
    }
  }}

  display: grid;
  align-items: start;
  grid-template-columns: ${sizes.sz024} auto ${sizes.sz024};
  gap: ${spacing.sp016};
  padding: ${spacing.sp016};
  background-color: var(--background-color);
  color: ${colors.white};
  border-radius: ${borderRadius.xl};
  box-shadow: ${shadows.m};
  cursor: default;
`;

export const CloseButton = styled.button`
  padding: 0;
  line-height: 0;
  background: transparent;
  border: none;
  color: ${colors.white};
`;
export const Icon = styled(DefaultIcon)`
  width: ${sizes.sz024};
  height: ${sizes.sz024};
`;

export const Text = styled(DefaultText)`
  margin: auto 0;
`;
