import { FC, useMemo } from "react";
import { LogoSVG } from "./logo.styles";
import LogoOnWhite from "$/ui/assets/svgs/brand/topi-logo-on-white.svg";
import LogoOnBlack from "$/ui/assets/svgs/brand/topi-logo-on-black.svg";
import LogoOnBlue from "$/ui/assets/svgs/brand/topi-logo-on-blue.svg";
import LogoOnPink from "$/ui/assets/svgs/brand/topi-logo-on-pink.svg";

export interface LogoProps {
  className?: string;
  variant?: "on-white" | "on-black" | "on-blue" | "on-pink";
  size?: "s" | "m";
}

export const Logo: FC<LogoProps> = ({
  className,
  variant = "on-white",
  size = "m",
}) => {
  const logoVariant = useMemo(() => {
    switch (variant) {
      case "on-white":
        return LogoOnWhite;
      case "on-black":
        return LogoOnBlack;
      case "on-blue":
        return LogoOnBlue;
      case "on-pink":
        return LogoOnPink;
      default:
        return LogoOnWhite;
    }
  }, [variant]);

  return <LogoSVG className={className} size={size} as={logoVariant} />;
};
