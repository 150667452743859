import { forwardRef } from "react";
import type { ReactNode } from "react";
import { Container } from "./text.styles";
import type { Colors, Typography } from "$/ui/styles/tokens/tokens";

export type TextTypography = Extract<
  Typography,
  | "overline"
  | "body1"
  | "body1Emphasis"
  | "body2"
  | "body2Emphasis"
  | "caption"
  | "captionEmphasis"
  | "headline4"
>;

export interface TextProps {
  children: ReactNode;
  className?: string;
  isTruncated?: boolean;
  tag?: "p" | "span" | "li" | "dt" | "dd" | "label" | "b" | "legend";
  testId?: string;
  typography?: TextTypography;
  color?: Colors;
}

export const Text = forwardRef<HTMLElement, TextProps>(
  (
    {
      children,
      className,
      isTruncated,
      tag = "p",
      testId,
      typography = "body1",
      color,
    },
    ref
  ) => (
    <Container
      as={tag}
      className={className}
      data-testid={testId}
      isTruncated={isTruncated}
      ref={ref}
      typography={typography}
      color={color}
    >
      {children}
    </Container>
  )
);
