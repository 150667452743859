import styled from "styled-components";
import { px2rems } from "$/ui/utils/px-to-rem";

export const LogoSVG = styled.svg<{ size: "s" | "m" }>`
  width: var(--logo-width);
  height: var(--logo-height);

  ${({ size }) => {
    switch (size) {
      case "s":
        return `
          --logo-width: ${px2rems(42)};
          --logo-height: ${px2rems(24)};
        `;

      case "m":
        return `
          --logo-width: ${px2rems(56)};
          --logo-height: ${px2rems(32)};
        `;

      default:
        return "";
    }
  }}
`;
