import { store as ecPortalStore } from "$/ec-portal-app/store-ec-portal";
import { store as checkoutStore } from "$/checkout-app/store-checkout";
import { store as sellerStore } from "$/seller-app/store-seller";

export function isCypressEnvironment(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !!window.Cypress;
}

type Store = typeof checkoutStore | typeof sellerStore | typeof ecPortalStore;

export function enableStoreForCypressEnvironment(store: Store): void {
  if (isCypressEnvironment()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.store = store;
  }
}

export function isStorybookEnvironment(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !!window.IS_STORYBOOK;
}

export function isPlaywrightEnvironment(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !!window.IS_PLAYWRIGHT;
}
