import { FlagProvider, IToggle } from "@unleash/proxy-client-react";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  url: string | undefined;
  proxyKey: string | undefined;
  currentApp: string | undefined;
  bootstrap?: IToggle[];
};

export const FeatureFlagsProvider = ({
  children,
  proxyKey,
  url,
  currentApp,
  bootstrap = [],
}: Props): JSX.Element => {
  if (
    url === undefined ||
    url === "" ||
    proxyKey === undefined ||
    proxyKey === "" ||
    currentApp === undefined ||
    currentApp === ""
  ) {
    throw new Error(
      "Unleash Proxy env variables shouldn't be undefined or empty strings"
    );
  }

  const unleashConfig = {
    url,
    bootstrap,
    clientKey: proxyKey,
    appName: currentApp,
  };

  return <FlagProvider config={unleashConfig}>{children}</FlagProvider>;
};
