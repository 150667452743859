import { AnalyticsBrowser } from "@segment/analytics-next";
import { useFlag } from "@unleash/proxy-client-react";
import { clarity } from "react-microsoft-clarity";
import { createContext, ReactNode, useContext, useMemo } from "react";
import {
  isCypressEnvironment,
  isStorybookEnvironment,
} from "$/utils/environment";
import {
  AnalyticsContextData as TAnalyticsContextData,
  AnalyticsContextProvider,
  useAnalyticsContext,
} from "./context";
// eslint-disable-next-line import/no-cycle
import { Logger } from "./logger";
import { useSegmentDebug } from "./use-segment-debug-hook";
import { SEGMENT_ENABLED_FLAG } from "$/feature-flags/constants";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AnalyticsContext = createContext<Analytics>(undefined!);

export class Analytics {
  analytics;

  analyticsEnabled: boolean;

  constructor(writeKey: string | undefined, analyticsEnabled: boolean) {
    const clarityID = process.env.CLARITY_ID ?? "";

    this.analyticsEnabled = analyticsEnabled;
    if (writeKey && this.analyticsEnabled) {
      this.analytics = AnalyticsBrowser.load({ writeKey });
    }
    if (this.analyticsEnabled) {
      /* eslint-disable i18next/no-literal-string */
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
      /* eslint-enable i18next/no-literal-string */
      clarity.init(clarityID);
    }
  }

  debug(flag: boolean): this {
    this.analytics?.debug(flag);
    return this;
  }

  withContext(context: TAnalyticsContextData): Logger {
    return new Logger(this, context);
  }
}

type Props = {
  writeKey?: string;
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analyticsEnabled =
    useFlag(SEGMENT_ENABLED_FLAG) &&
    !isCypressEnvironment() &&
    !isStorybookEnvironment();
  const analytics = useMemo(() => {
    return new Analytics(writeKey, analyticsEnabled);
  }, [writeKey, analyticsEnabled]);

  const isDebugEnabled = useSegmentDebug();
  if (isDebugEnabled) {
    analytics.debug(true);
  }

  return (
    <AnalyticsContext.Provider value={analytics}>
      <AnalyticsContextProvider contextData={{}}>
        {children}
      </AnalyticsContextProvider>
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): Logger => {
  const analyticsContext = useAnalyticsContext();
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error("AnalyticsContext used outside of its Provider!");
  }

  return result.withContext(analyticsContext);
};
